/* eslint-disable @angular-eslint/component-selector */
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { LoaderService } from '@schneideress/za-ui-controls';
import { TranslateService } from '@schneideress/za-ui-controls';

@Component({
  selector: 'hub-translate-loader',
  templateUrl: './translate.loader.component.html',
})
export class TranslateLoaderComponent implements OnInit {
  @Input() moduleName = '';
  @Input() contentTemplate?: TemplateRef<any>;
  @ContentChild(TemplateRef) detailRef?: TemplateRef<any>;

  translationLoaded = false;

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
    this.translateService.initializeTranslations();
    this.loadTranslations();
  }

  private loadTranslations(): void {
    this.translateService.loadTranslations(this.moduleName).subscribe(() => {
      setTimeout(() => {
        this.translationLoaded = true;
        this.loaderService.hideLoader();
      });
    });
  }
}
