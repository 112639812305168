import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RoadmapService } from 'src/app/home/plan/roadmap/roadmap.service';
import { TranslateService } from '@schneideress/za-ui-controls';

@Component({
  selector: 's3-allocator',
  templateUrl: 'service.allocator.component.html',
  styleUrls: ['service.allocator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceAllocatorComponent),
      multi: true,
    },
  ],
})
export class ServiceAllocatorComponent implements OnInit, ControlValueAccessor {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange = new EventEmitter();

  datastreams: any[] = [];

  private values?: any[];
  private siteGuid: any;

  constructor(
    private route: ActivatedRoute,
    private roadmapSerive: RoadmapService,
    private translateService: TranslateService,
  ) {}

  propagateChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.siteGuid = this.route.snapshot.params['siteGuid'];
    this.roadmapSerive.getDataStreamsBySiteId(this.siteGuid).subscribe((result: any) => {
      if (result && result.length > 0) {
        this.datastreams = result.map((element: any) => {
          return {
            id: element.DataStreamId,
            name: element.DataStreamName,
            namekey: element.DataStreamNameKey,
            DisplayText: this.translateService.translate('shared.' + element.DataStreamNameKey),
          };
        });
        this.setValue();
      }
    });
  }

  writeValue(values: any): void {
    this.values = values;
    this.setValue();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valueChanged(value: any): void {
    let values: any = null;
    if (this.datastreams) {
      if (
        this.datastreams.filter((item: any) => {
          return !item.value;
        }).length == 0
      ) {
        values = this.datastreams;
      }
    }
    this.onChange.emit(values);
  }

  private setValue(): void {
    if (this.datastreams && this.values) {
      for (let i = 0; i < this.datastreams.length; i++) {
        for (let j = 0; j < this.values.length; j++) {
          if (this.datastreams[i].id == this.values[j].id) {
            this.datastreams[i].value = this.values[j].value;
          }
        }
      }
    }
  }
}
