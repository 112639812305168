import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private httpService: HttpClientService) {}
  getSiteList(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/site/list');
  }
}
