import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '@schneideress/za-ui-controls';
import { IClientSubscription } from '../sustainability-common/models/clientSubscription.interface';
import { ISubscription } from '../sustainability-common/models/subscription.interface';
import { IClient } from '../types/client.type';
import { Observable, map, take, tap } from 'rxjs';

export interface IApiClient extends Omit<IClient, 'ClientAttribute' | 'Subscription'> {
  ClientAttribute: string;
  Subscription?: IApiClientSubscription;
}
interface IApiClientSubscription
  extends Omit<IClientSubscription, 'StartDate' | 'EndDate' | 'Subscription'> {
  StartDate: string;
  EndDate: string;
  Subscription: IApiSubscription;
}
interface IApiSubscription extends Omit<ISubscription, 'AvailableStartDate' | 'AvailableEndDate'> {
  AvailableStartDate: string;
  AvailableEndDate: string;
}

@Injectable({ providedIn: 'root' })
export class CompanyService {
  constructor(private httpService: HttpClientService) {}

  getCurrentClient(): Observable<IClient> {
    return this.httpService.get(environment.apiBaseUrl + '/company').pipe(
      take(1),
      map((client: IApiClient) => this.toClient(client)),
    );
  }

  updateClient(client: IClient): Observable<IClient> {
    return this.httpService
      .post(environment.apiBaseUrl + '/company', this.toApiClient(client))
      .pipe(
        map((client: IApiClient) => this.toClient(client)),
        tap(() => {
          this.getCurrentClient()
            .pipe(take(1))
            .subscribe(() => {});
        }),
      );
  }

  getClientAddressInfo(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/company/address');
  }

  private toClient(client: IApiClient): IClient {
    return {
      ...client,
      ClientAttribute: client.ClientAttribute ? JSON.parse(client.ClientAttribute) : {},
      Subscription: client.Subscription
        ? this.toClientSubscription(client.Subscription)
        : undefined,
    } as IClient;
  }

  private toApiClient(client: IClient): IApiClient {
    return {
      ...client,
      ClientAttribute: JSON.stringify(client.ClientAttribute),
      // The dates will automatically serialize correctly so no need to convert those
    } as IApiClient;
  }

  private toClientSubscription(clientSubscription: IApiClientSubscription): IClientSubscription {
    return {
      ...clientSubscription,
      StartDate: new Date(clientSubscription.StartDate),
      EndDate: new Date(clientSubscription.EndDate),
      Subscription: this.toSubscription(clientSubscription.Subscription),
    };
  }

  private toSubscription(subscription: IApiSubscription): ISubscription {
    return {
      ...subscription,
      AvailableStartDate: new Date(subscription.AvailableStartDate),
      AvailableEndDate: new Date(subscription.AvailableEndDate),
    };
  }
}
