import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { CookieService } from './sustainability-common';
import { CacheService } from '@schneideress/za-ui-controls';
import { LoaderService } from '@schneideress/za-ui-controls';
import { GaDataLayerService } from './ga-datalayer/ga-datalayer.service';
import { VersionService } from './sustainability-common/services/version.service';
import { WindowRefService } from './ga-datalayer/windowref.service';

@Component({
  selector: 's3-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CookieService],
})
export class AppComponent implements OnInit {
  showLoader = true;
  visitor = false;

  constructor(
    public authService: AuthService,
    private cookieService: CookieService,
    private cacheService: CacheService,
    private loaderService: LoaderService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private gaDataLayerService: GaDataLayerService,
    private versionService: VersionService,
    private windowRefService: WindowRefService,
  ) {}

  ngOnInit(): void {
    // Subscribe to router events to show loading panel when lazy modules are being loaded.
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loaderService.showLoader();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loaderService.hideLoader();
      } else if (event instanceof NavigationEnd) {
        this.gaDataLayerService.pushPageView(event.url);
      }
    });
    // Subscribe to show full screen loader
    this.loaderService.loader.subscribe((show: boolean) => {
      this.showLoader = show;
      this.cdr.detectChanges();
    });
    // Cookie
    if (!this.cookieService.getCookieValue('ZSC')) {
      this.cacheService.clear();
    }
    this.cookieService.setSessionCookie('ZSC');

    if (this.windowRefService.nativeWindow.location.href.toLowerCase().includes('/visitor/')) {
      this.visitor = true;
    }

    if (!this.visitor) {
      // Listen to auth subjects
      this.authService.listenToAuthSubjects();
      this.versionService.initializeVersion();
    }
  }
}
