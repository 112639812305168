<div class="modal-header">
  <button
    ttype="button"
    class="btn-close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="onClose()"></button>
</div>
<div class="modal-body">
  <div class="text-center">
    <img class="mb-5" src="assets/images/baseline.png" />
  </div>
  <div>
    <s3-text class="d-flex justify-content-center" [type]="'3XLarge'" weight="Bold">
      {{ 'shared.success' | translate }}!
    </s3-text>
  </div>
  <div class="mb-5 text-center">
    <s3-text type="XLarge" [color]="'var(--s3-color-default-dark)'">{{
      'shared.' + taskname + 'task' | translate
    }}</s3-text>
  </div>
  <hr width="100%" />
</div>
<div class="modal-footer">
  <div class="button-container" id="butn">
    <span
      ><s3-button appearance="outline" (click)="navigateToDashboard()">{{
        'shared.taskmodalreturnbutton' | translate
      }}</s3-button></span
    >
    <span
      ><s3-button (click)="onClose()">{{ 'shared.stayhere' | translate }}</s3-button></span
    >
  </div>
</div>
