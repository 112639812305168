import { Injectable } from '@angular/core';
import { Observable, map, take, tap } from 'rxjs';

import { HttpClientService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';
import { IUserInfo } from '../types/user.type';
import { CacheStorageType } from '../sustainability-common/models/cache-storage-type.enum';
import { UserRole } from '../types/user-role.enum';

@Injectable()
export class UserService {
  constructor(private httpService: HttpClientService) {}

  getUser(updateCache = false): Observable<IUserInfo> {
    return this.httpService.get(
      environment.apiBaseUrl + '/user/userInfo',
      true,
      CacheStorageType.window,
      updateCache,
    );
  }

  updateUser(user: IUserInfo): Observable<IUserInfo> {
    return this.httpService.post(environment.apiBaseUrl + '/user/userDetails', user).pipe(
      tap(() => {
        this.getUser(true)
          .pipe(take(1))
          .subscribe(() => {});
      }),
    );
  }

  updateUserSessionActivity(UserAgent: any): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/user/sessionActivity', UserAgent);
  }

  userHasPermission(
    roles: UserRole[],
    siteIds: any[] | null | undefined = null,
  ): Observable<boolean> {
    return this.getUser().pipe(
      map(user => {
        return roles.some(role => {
          if (user.Roles?.includes(role) ?? false) {
            if (role === UserRole.SiteManager && siteIds?.length) {
              return (
                user.SiteIdsWithPermissions?.some(userSite => {
                  return siteIds.some(site => {
                    return userSite == site; // loose equality
                  });
                }) ?? false
              );
            }

            return true;
          }

          return false;
        });
      }),
    );
  }
}
