export const S3Constants = {
  TARGET_MAX_YEAR: 2050,
  CODES: {
    NO_OF_EMPLOYEES: 'No. Of Employees',
    USER_ROLE: 'User Role',
    SITE: 'Site',
    CLIENT: 'Client',
    COST: 'Cost',
    USAGE: 'Usage',
    EMISSION_TYPE: 'Emission Type',
    SiteType: 'Site Type',
    Emission_Type: 'Emission Type',
  },
  BASELINE_START_YEAR: 2017,
  TRACK_FLYOUT_TYPE: {
    InvoiceInformation: 'InvoiceInformation',
    EnergySources: 'EnergySources',
    InvoiceList: 'InvoiceList',
    Fleet: 'Fleet',
    CarbonOffset: 'Carbon Offset',
    UploadBill: 'Upload Bill',
    ManageInvoices: 'ManageInvoices',
    Scope3: 'Scope3',
    ExcelUpload: 'ExcelUpload',
    TransactionList: 'TransactionList',
  },
  EVENTS: {
    RELOADGRID: 'reloadGrid',
    CLOSEFLYOUT: 'closeFlyout',
  },
  ELECTRIC_POWER: 'Electric Power',
  REQUIRED: 'required',
  RENEWABLE: 'Renewable',
  GREENTARIFF: 'Green Tariff',
  LEASED: 'Leased',
  CARBONOFFSET: 'Carbon Offset',
  SCOPE3: 'Scope3',
  UTILITY: '(Utility %)',
  AREA: 'area',
  ENERGY_SYSTEMS: 'Energy Systems',
  COMPLETED: 'Completed',
  NOT_INTERESTED: 'Not Interested',
  NOT_STARTED: 'Not Started',
  FAILED: 'failed',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  INPROGRESS: 'In Progress',
  ELECTRICITY: 'Electricity',
  FOSSIL_FUELS: 'FossilFuels',
  COMBINED: 'Combined',
  GREEN_TARIF_OPTIONS: {
    Utility_Percentage_covered: 'Utility Percentage covered',
    Usage_Volume: 'Usage Volume',
  },
  OWNERSHIP: {
    OWNED: 'Owned',
    MIXED: 'Mixed',
    LEASED: 'Leased',
  },
  ONSITERENEWABLES: 'On Site Renewables',
  ON_SITE_RENEWABLES_OPTIONS: {
    I_Keep_And_Use_The_Generated_Electricity: 'I keep and use the generated electricity',
    I_Export_The_Generated_Electricity: 'I export the generated electricity',
    SUB_OPTION: {
      I_Keep_The_Certificate: 'I keep the certificate',
      Not_Keep_The_Certificate: 'I don’t keep the certificate',
    },
  },
  SUBTRACT: 'Subtract',
  ADD: 'Add',
  IGNORE: 'Ignore',
  EAC: 'EACs/RECs/GOs',
  PPA: 'PPA',
  PPA_RENEWABLES_OPTION: {
    I_Keep_And_Retire_The_EAC_Generated: 'I keep and retire the EAC’s generated',
    Not_Keep_And_Retire_The_EAC_Generated: 'I don’t keep and retire the EAC’s',
    SUB_OPTION: {
      Included_In_The_Utility_Amount: 'Included in the utility amount',
      Not_Included_In_The_Utility_Amount: 'Not included in the utility amount',
    },
  },
  Proejct_Category: {
    Energy_Efficiency: 'Energy Efficiency',
    Quick_Fix: 'Quick fix',
    Category_Carbon_Offset: 'Carbon Offset',
    Category_Renewable: 'Renewable',
  },
  Project_Type: {
    FLEET_ELECTRIFICATION: 'Fleet Electrification',
    GREENTARIFF_US: 'Green Tariff (US)',
    GREENTARIFF_EU: 'Green tariff (EU)',
    GREENTARIFF_OUTSIDE_US_EU: 'Green tariff (Outside of the US and EU)',
    PPA: 'PPA',
    ONSITE_SOLAR: 'Onsite Solar',
    EAC: 'Energy Attribute Certificates(EAC)',
    MICROGRID: 'Microgrid',
  },
  InComplete_Site: 'inCompleteSite',
  EmissionUOMs: ['MTCO2e', 'tCO2e'],
  SCOPETWO: 'Scope 2',
  ENERGY_EFFICIENCY: 'EnergyEfficiency',
  QUICKFIX: 'Quickfix',
  CARBON_OFFSET: 'CarbonOffset',
  NA: 'N/A',
  PROJECT_STATUS: 'Project Status',
  COMPANY_LEVEL_PERCENTAGE_QUESTION:
    'How much do you expect your company to grow between now and your target year?',
  IsLoggedIn: 'IsLoggedIn',
  SHOW_TOUR: 'showTour',
  BASELINE: 'Baseline',
  NEXT_TOUR: 'NextTour',
  DASHBOARD: 'Dashboard',
  OPEN_PAGE_TOUR_FLYOUT: 'OpenPageTourFlyout',
  LANGUAGE: 'Language',
  TRANSLATIONSTRING: 'translationStrings',
  TRANSLATIONMODULES: 'translationModules',
  NODATA: 'No Data',
  ACTIVITY: 'Activity',
  SECTOR: 'Sector',
  INDUSTRY: 'Industry',
  TRACKMODULE: 'track',
  ENERGYDATAENTRY: 'Energy Data Entry',
  FileLimit: 6,
  BASIC_USER_SITE_LIMIT: 1,
  ECOCONSULT: 'EcoConsult by Schneider Electric',
  ENERGY_CONSULTING: 'Energy Consulting',
  CALENDAR_YEAR: 'Calendar Year',
  INVOICEALREADYEXIST: 'invoicealreadyexist',
  NOTIFICATIONS: 'Notifications',
  BASIC: 'Basic',
  EDITBASELINE: 'EditBaseline',
  MANAGEINVOICES: 'ManageInvoices',
  SITEMANAGEMENT: 'SiteManagement',
  DOWNLOADREPORT: 'DownloadReport',
  ADMIN_LIMIT: 50,
  SITE_MANAGER_LIMIT: 50,
  GUEST_LIMIT: 50,
  ANNOUNCEMENTS: 'announcements',
  TASKLIST_PAYWALL: {
    ROADMAP: 'Roadmap',
    REPORTCENTER: 'Report Center',
    ROADMAPTASK: 'Roadmap Task',
  },
  // NEXT_TOUR: 'NextTour',
  // DASHBOARD: 'Dashboard',
  // NOTIFICATIONS: 'Notifications',
  // CALENDAR_YEAR: 'Calendar Year',
  PERFORMANCE_HIGH: 'High',
  PERFORMANCE_LOW: 'Low',
  PERFORMANCE_AVERAGE: 'Average',
  PROVIDER_NAME_ALREADY_EXIST: 'Provider Name already exist',
  EMAIL_ADREES_ALREADY_EXIST: 'Email address already exist',
};
