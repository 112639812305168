import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../common';
import { LoaderService } from '@schneideress/za-ui-controls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnBoardingGuard {
  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private loaderService: LoaderService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.loaderService.showLoader();
    const url = route.url.map(segment => segment.path).join('/');
    return new Observable<boolean>(observable => {
      this.userService.getUser(true).subscribe(userInfo => {
        this.loaderService.hideLoader();
        if (userInfo.ClientID && url.endsWith('onboarding')) {
          this.router.navigate(['home']);
        } else if (!userInfo.ClientID && !url.endsWith('onboarding')) {
          this.router.navigate(['onboarding']);
        }
        observable.next(true);
      });
    });
  }
}
