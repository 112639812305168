import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UOMValueComponent } from './uom-value/uom-value.component';
//import { NotificationService } from '@progress/kendo-angular-notification';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { SiteControlComponent } from './site-control/site-control.component';
import { ServiceAllocatorComponent } from './service-allocator/service.allocator.component';
import { SuspendedSubscriptionComponent } from './suspended-subscription/suspended-subscription.component';
import { RouterModule } from '@angular/router';
import { UiModule } from '@schneideress/za-ui-controls';

const Controls: any[] = [
  UOMValueComponent,
  SiteControlComponent,
  ServiceAllocatorComponent,
  SuspendedSubscriptionComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownsModule,
    NgbModule,
    FormsModule,
    DateInputsModule,
    InputsModule,
    LabelModule,
    RouterModule,
    UiModule,
  ],
  exports: [Controls, ReactiveFormsModule, CommonModule, FormsModule, UiModule],
  declarations: [Controls],
  providers: [NgbActiveModal],
})
export class UIControlsModule {}
