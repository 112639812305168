import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccessManagementService } from 'src/app/common/access-management.service';
import { SustainabilityCommonModule } from 'src/app/sustainability-common/sustainability.common.module';
import { InvitationStatus } from 'src/app/types/invitation-status.enum';
import { IInvitationValidation } from 'src/app/types/invitation-validation.type';
import {
  LoaderService,
  NotificationToastComponent,
  TranslateService,
  UiModule,
} from '@schneideress/za-ui-controls';
import { UIControlsModule } from 'src/app/ui-controls/ui.controls.module';
import { WindowRefService } from 'src/app/ga-datalayer/windowref.service';

@Component({
  selector: 's3-invite-landing',
  standalone: true,
  imports: [CommonModule, SustainabilityCommonModule, UIControlsModule, UiModule],
  templateUrl: './invite-landing.component.html',
  styleUrl: './invite-landing.component.scss',
})
export class InviteLandingComponent implements OnInit, OnDestroy {
  @ViewChild('contactSuccess') private contactSuccessToast!: NotificationToastComponent;
  @ViewChild('contactError') private contactErrorToast!: NotificationToastComponent;

  validating = true;
  redirecting = false;
  showError = false;
  validationResult: IInvitationValidation | undefined;
  InvitationStatus = InvitationStatus;

  private emailAddress: string = '';
  private token: string = '';
  private accepted: boolean = true;
  private millisecondsInADay = 1000 * 60 * 60 * 24;

  private paramSub?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accessManagementService: AccessManagementService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private window: WindowRefService,
  ) {}

  ngOnInit(): void {
    this.paramSub = this.route.queryParamMap.subscribe(p => {
      this.emailAddress = p.get('email') ?? '';
      this.token = p.get('token') ?? '';

      const acceptedQuery = p.get('accepted');
      if (acceptedQuery !== null) {
        this.accepted = acceptedQuery?.toLowerCase() === 'true';
      }

      if (this.emailAddress && this.token) {
        this.validateInvitation();
      } else {
        this.showErrorContent();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  getExpirationDays(): number {
    if (this.validationResult !== undefined) {
      return Math.round(
        (new Date(this.validationResult.ExpireDateTime).getTime() -
          new Date(this.validationResult.CreatedDateTime).getTime()) /
          this.millisecondsInADay,
      );
    } else {
      return 0;
    }
  }

  contactAdmin(): void {
    this.accessManagementService.contactAdmin(this.emailAddress, this.token).subscribe({
      next: r => {
        if (r) {
          this.contactSuccessToast.showToast();
        } else {
          this.contactErrorToast.showToast();
        }
      },
      error: () => {
        this.contactErrorToast.showToast();
      },
    });
  }

  private validateInvitation(): void {
    this.loaderService.showLoader();
    this.validating = true;
    this.redirecting = false;
    this.showError = false;

    this.accessManagementService.validateInvitation(this.emailAddress, this.token).subscribe({
      next: v => {
        this.validationResult = v;
        if (v.IsValid) {
          if (this.accepted) {
            this.redirecting = true;
            this.validating = false;
            this.overrideUrlForAuthenticationRedirect();
            this.router.navigate(['home']);
          } else {
            this.declineInvitation();
          }
        } else {
          this.validating = false;
          this.loaderService.hideLoader();
        }
      },
      error: () => {
        this.showErrorContent();
      },
    });
  }

  private overrideUrlForAuthenticationRedirect(): void {
    const baseUrl = this.window.nativeWindow.location.href.split('visitor/invite-landing?')[0];
    const newUrl = `${baseUrl}home`;
    this.window.nativeWindow.history.replaceState({}, '', newUrl);
  }

  private declineInvitation(): void {
    this.loaderService.showLoader();
    this.accessManagementService.declineInvitation(this.emailAddress, this.token).subscribe({
      next: r => {
        if (r) {
          this.validationResult!.Status = InvitationStatus.Declined;
          this.validating = false;
          this.loaderService.hideLoader();
        } else {
          this.showErrorContent();
        }
      },
      error: () => {
        this.showErrorContent();
      },
    });
  }

  private showErrorContent(): void {
    this.showError = true;
    this.redirecting = false;
    this.validating = false;
    this.loaderService.hideLoader();
  }
}
