import { Injectable } from '@angular/core';
import { HttpClientService, TranslateService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  versionText: string = '';

  constructor(
    private http: HttpClientService,
    private translateService: TranslateService,
  ) {}

  initializeVersion(): void {
    const versionText = this.translateService.translate('shared.versiontext');
    this.http.get(environment.apiBaseUrl + '/version').subscribe(versionNumber => {
      this.versionText = versionText + ': ' + versionNumber;
    });
  }
}
