import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SustainabilityCommonModule } from './sustainability-common/sustainability.common.module';
import { ApiModule } from './common/common.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UIControlsModule } from './ui-controls/ui.controls.module';
import { GaDataLayerModule } from './ga-datalayer/ga-datalayer.module';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { getProtectedResourceMap, msalConfig } from './auth/auth.config';
import { IconsModule } from '@progress/kendo-angular-icons';
import { UiModule } from '@schneideress/za-ui-controls';
import { CancelRepeatedRequestsInterceptor } from './interceptors/cancel-repeated-requests.interceptor';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = getProtectedResourceMap();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.auth.scopes],
    },
    loginFailedRoute: '/',
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SustainabilityCommonModule.forRoot({
      sustainabilityHubBaseUrl: environment.sustainabilityHubBaseUrl,
    }),
    BrowserAnimationsModule,
    ApiModule,
    GaDataLayerModule,
    UIControlsModule,
    MsalModule,
    IconsModule,
    UiModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CancelRepeatedRequestsInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
